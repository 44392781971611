import gql from "graphql-tag";

export const customerApplicationsQuery = gql`
  query customerApplications($orderBy: OrderBy, $filters: Filter, $page: Int, $perPage: Int) {
    customerApplications(orderBy: $orderBy, filters: $filters, page: $page, first: $perPage) {
      paginatorInfo {
        currentPage
        perPage
        total
        lastPage
      }
      data {
        id
        companyName
        companyCode
        taxNumber
        comment
        status
        user {
          id
          firstName
          lastName
          phone
          email
          roles {
            name
          }
        }
      }
    }
  }
`;
