<template>
  <div class="search-wrapper" :class="focusClass" @keyup.enter="onClickSearch">
    <!-- @slot Use it to add something before text. -->
    <slot name="left" />

    <t-input
      :id="id"
      ref="searchInput"
      v-model="search"
      class="search-input"
      type="search"
      inputmode="search"
      :placeholder="placeholder"
      :class="inputSizeClass"
      :data-cy="dataCy"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    />

    <OurSvgIcon
      v-if="hasClearButton"
      :src="icons.Close"
      class="search-icon-close"
      color="gray"
      interactive
      :data-cy="`${dataCy}-close`"
      :size="iconSize"
      @click="onClickClear"
    />

    <OurSvgIcon
      v-if="hasSearchButton"
      :src="icons.Search"
      class="search-icon"
      interactive
      :size="iconSize"
      :data-cy="`${dataCy}-search`"
      @click="onClickSearch"
    />
  </div>
</template>

<script>
import { getRandomId } from "@/services/_bit/UiService";

import OurSvgIcon from "@/components/_bit/IconSvg";
import TInput from "vue-tailwind/dist/t-input";

export default {
  name: "SearchInput",

  components: {
    OurSvgIcon,
    TInput,
  },

  props: {
    /**
     * Set component value.
     */
    value: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    /**
     * Set input size.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Generates unique element id.
     * @ignore
     */
    id: {
      type: String,
      default: () => getRandomId(),
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },

    hasSearchButton: {
      type: Boolean,
      default: true,
    },

    hasClearButton: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      focus: false,
    };
  },

  computed: {
    search: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    icons: () => ({
      Close: require("@/components/_bit/IconSvg/icons/Close.svg"),
      Search: require("@/components/_bit/IconSvg/icons/Search.svg"),
    }),

    focusClass() {
      return this.focus ? "focus" : "";
    },

    inputSizeClass() {
      return {
        "size-sm": this.size === "sm",
        "size-md": this.size === "md",
        "size-lg": this.size === "lg",
      };
    },

    iconSize() {
      const sizes = {
        sm: "xs",
        md: "sm",
        lg: "md",
      };

      return sizes[this.size];
    },
  },

  methods: {
    onClickClear() {
      this.search = "";
      this.$emit("clear");
    },

    onClickSearch() {
      this.focus = true;
      if (!this.search) return;
      this.$emit("search", this.search);
    },

    onFocus() {
      this.focus = true;
      this.$emit("focus");
    },

    onBlur() {
      this.focus = false;
    },

    onInput() {
      this.$emit("on-input");
    },
  },
};
</script>

<style lang="postcss" scoped>
.search {
  &-wrapper {
    @apply relative;
    @apply flex items-center justify-between;
    @apply w-full rounded-lg;
    @apply bg-gray-900 bg-opacity-5;
    @apply pr-3;
  }

  &-input {
    @apply h-full;
    @apply border-0 bg-transparent shadow-none focus:ring-0;
    @apply font-normal text-gray-900;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }

  &-icon {
    @apply flex h-full items-center;
    @apply ml-2;

    &:deep(g [fill]) {
      @apply fill-current text-gray-900 opacity-100;
    }

    &-close {
      @apply flex h-full items-center;
      @apply absolute right-8 top-0;
    }
  }
}

.size {
  &-sm {
    @apply mb-0.5;
    @apply text-sm;
  }

  &-md {
    @apply mb-1 mt-0.5;
  }

  &-lg {
    @apply mb-2 mt-1.5;
    @apply text-lg;
  }
}
</style>
