import NotifyService from "@/services/notify.service";
import { filterBuilder } from "@/services/filterBuilder.service";
import store from "@/store";
import router from "@/router";

const defaultSortingOptions = {
  column: "id",
  direction: "asc",
};

export default {
  methods: {
    /**
     * @param { Object } gqlQuery
     * @param { Object } options
     * @param { Object } settings
     * @returns { Object } api response
     */

    async $get(gqlQuery, options = {}, settings = {}) {
      const { withLoader = true, withNotify = false, delaySuccessNotify = false } = settings;

      try {
        if (withLoader) this.loader("on");

        if (Object.keys(options).length) {
          if (options?.filtersOptions?.length) {
            const filtersOptions = JSON.parse(JSON.stringify(options.filtersOptions));

            options.filters = filterBuilder(filtersOptions);
          }

          options = {
            ...options,
            orderBy: options.sortingOptions || defaultSortingOptions,
            page: options.page || 1,
            perPage: options.perPage || 25,
          };
        }

        const response = await this.$apollo.query({
          query: gqlQuery,
          variables: options,
        });

        if (withNotify) {
          const [message] = Object.keys(response.data);

          if (delaySuccessNotify) {
            const type = "success";

            localStorage.setItem("notify", JSON.stringify({ type, message }));
          } else {
            NotifyService.success(message);
          }
        }

        return response?.errors && response?.errors[0]?.message.includes("No query results")
          ? router.push("/404")
          : response.data;
      } catch (error) {
        if (withNotify) showNotifyError(error);
      } finally {
        this.loader("off");
      }
    },

    /**
     * @param { Object } gqlMutations
     * @param { Object } variables
     * @param { Object } settings
     * @returns {Promise<object>}
     */

    async $post(gqlMutations, variables, settings = {}) {
      const { withLoader = true, withNotify = true, delaySuccessNotify = false } = settings;

      try {
        if (withLoader) this.loader("on");

        const response = await this.$apollo.mutate({
          mutation: gqlMutations,
          variables: variables,
        });

        if (withNotify) {
          const [message] = Object.keys(response.data);

          if (delaySuccessNotify) {
            const type = "success";

            localStorage.setItem("notify", JSON.stringify({ type, message }));
          } else {
            NotifyService.success(message);
          }
        }

        return response.data;
      } catch (error) {
        if (withNotify) showNotifyError(error);

        return {};
      } finally {
        this.loader("off");
      }
    },

    /**
     * Change loaders state (on | off)
     * @param { String } state
     */
    loader(state) {
      if (state === "on") store.commit("loaderTop/SET_LOADING_ON");
      if (state === "off") store.commit("loaderTop/SET_LOADING_OFF");
    },
  },
};

function showNotifyError(error) {
  if (error.graphQLErrors.length) {
    error.graphQLErrors.forEach((errorItem) => {
      const message = errorItem?.extensions?.message;

      if (Array.isArray(message) && message.length) {
        message.forEach((code) => NotifyService.error(code));
      } else {
        NotifyService.notify({ type: "error", text: message });
      }
    });
  } else {
    NotifyService.error();
  }
}
