<template>
  <div class="h-full" :class="appClasses" :style="cssVariables">
    <router-view />
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import { convertHexInRgb, isPWA } from "@/services/_bit/UiService";

export default {
  name: "App",

  computed: {
    ...mapState("user", ["accentColor"]),
    ...mapGetters("user", ["isUserDealer"]),

    cssVariables() {
      return {
        "--accent-color": convertHexInRgb(this.accentColor),
      };
    },

    appClasses() {
      return {
        "pwa-app": isPWA,
      };
    },
  },

  mounted() {
    const isUserDealer = !!Number(localStorage.getItem("isUserDealer"))

    if (isUserDealer) {
      document.addEventListener("click", this.handleGlobalClick);
    }
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleGlobalClick);
  },

  methods: {
    handleGlobalClick(event) {
      window.dataLayer = window.dataLayer || []
      const target = event.target
      const tags = [
        'A', 'BUTTON', 'IMG',
        'TD', 'TH', 'H1',
        'H2', 'H3', 'H4', 'H5', 'H6',
        'SPAN', 'DIV'
      ]

      if (tags.includes(target.tagName)) {

        if (event.target.classList.contains('our-page-additional-wrapper')
          || event.target.classList.contains('our-page-wrapper')
          || event.target.classList.contains('our-page')
        ) return

        window.dataLayer.push({
          "event": 'Click',
          "clickTex": event.target?.innerText || '',
          "clickID": event.target?.id || '',
          "clickClasses": event.target?.className || '',
          "clickURL": event.target?.href || ""
        })
      }
    },
  },
};
</script>
