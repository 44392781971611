import i18n from "@/plugins/vue-i18n";
import { fullTextSearch } from "@/api/products";

export default {
  namespaced: true,
  state: {
    status: {
      free: {
        label: i18n.t("product.status.available"),
        color: "green",
        value: "free",
      },
      reserved: {
        label: i18n.t("product.status.reserved"),
        color: "yellow",
        value: "reserved",
      },
      notAvailable: {
        label: i18n.t("product.status.notAvailable"),
        color: "red",
        value: "notAvailable",
      },
      expected: {
        label: i18n.t("product.status.expected"),
        color: "violet",
        value: "expected",
      },
    },
    cartProducts: [],
    searchForm: {
      category: {},
      search: "",
      contract: "",
      name: "",
      status: "",
    },
    filtersForm: {
      name: "",
      status: "",
    },
  },
  getters: {
    getStatus(state) {
      return (status, date) => {
        if (state.status[status]) {
          if (state.status[status].value === "expected") {
            const { label, color, value } = state.status[status];

            return {
              label: `${label} ${date}`,
              color,
              value,
            };
          }

          return state.status[status];
        }
      };
    },

    getStatuses(state) {
      return Object.values(state.status);
    },

    getCartProducts(state) {
      const { cartProducts } = state;

      cartProducts.forEach((item) => {
        if (!item.quantity) item.quantity = 1;
      });

      return cartProducts;
    },

    getCategories(state) {
      return state.searchForm.category;
    },

    getFilterForm(state) {
      return state.filtersForm;
    },
  },
  mutations: {
    SET_CART_PRODUCTS(state, cart) {
      state.cartProducts = cart;
    },

    ADD_PRODUCT_TO_CART(state, product) {
      const { cartProducts } = state;
      const index = cartProducts.findIndex((item) => parseInt(item.id) === parseInt(product.id));

      if (parseInt(cartProducts[index]?.id) === parseInt(product.id)) {
        const stateProduct = cartProducts[index];

        stateProduct["quantity"] += product["quantity"];
        // stateProduct["quantity"]++;

        cartProducts.splice(index, 1, stateProduct);
      } else {
        cartProducts.push(product);
      }
    },

    DELETE_PRODUCT_FROM_CART(state, productId) {
      const { cartProducts } = state;
      const index = cartProducts.findIndex((item) => item.id === productId);

      cartProducts.splice(index, 1);

      if (!cartProducts.length) state.cartProducts = [];
    },

    SET_PRODUCT_QUANTITY(state, [productId, newProductQuantity]) {
      const { cartProducts } = state;
      const index = cartProducts.findIndex((item) => item.id === productId);
      const product = cartProducts[index];

      product["quantity"] = newProductQuantity;
      cartProducts.splice(index, 1, product);
    },

    SET_CATEGORY(state, category) {
      state.searchForm.category = category;
    },

    SET_SEARCH(state, search) {
      state.searchForm.search = search;
    },

    SET_CONTACT(state, contract) {
      state.searchForm.contract = contract;
    },

    SET_FILTERS_FORM(state, form) {
      state.filtersForm = form;
    },

    CLEAR_CATEGORY(state) {
      state.searchForm.category = {};
    },
  },

  actions: {
    async getProductsDataByFullTextSearch(context, searchData) {
      return { products: await fullTextSearch(searchData) };
    },
  },
};
