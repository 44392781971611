<script>
import { mapGetters, mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { customerApplicationQuery } from "./gql/queries";
import {
  sendCustomerApplicationToOneCMutation,
  updateCustomerApplicationMutation,
} from "./gql/mutations";
import ApplicationsForm from "@/views/settings/new-applications/_components/ApplicationsForm.vue";
import { accessGroupsQuery } from "@/views/settings/dealer-company/add/gql/queries";
import { storagesQuery } from "@/views/settings/customers/add/gql/queries";
import { rolesQuery } from "@/views/settings/dealer-users/add/gql/queries";
import { getCompanies } from "@/api/companies";
import { managersQuery } from "@/views/settings/dealer-company/index/gql/queries";

export default {
  name: "DealerUsersEdit",

  components: {
    ApplicationsForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      isShownDeleteModal: false,
      isShownRestoreModal: false,
      backRoute: {
        name: "NewApplications",
        title: this.$t("title.newApplications._"),
      },
      customer: "",
      customerId: "",
      companies: [],
      alreadySentToOneC: false,
      loading: false,
      form: {
        companyName: "",
        name: "",
        taxNumber: "",
        lastName: "",
        firstName: "",
        roleId: "",
        region: "",
        managerId: "",
        processManagerId: "",
        email: "",
        phone: "",
        comment: "",
        storageId: "",
        accessGroupId: "",
        companies: [],
      },
    };
  },

  computed: {
    ...mapGetters("user", ["userProfile", "isUserDealer", "getPermission"]),
    ...mapGetters("dealerUsers", ["getStatus"]),

    fullName() {
      const { firstName, lastName } = this.form;

      return `${lastName} ${firstName}`;
    },

    havePermissionEdit() {
      return this.getPermission["customer.applications"];
    },

    havePermissionDelete() {
      return this.getPermission["customer.applications"];
    },
  },

  async created() {
    await this.getData();
    this.getAccessGroups();
    this.getStorages();
    this.getRoles();
    this.getManagers();
  },

  methods: {
    ...mapMutations("dealerUsers", ["SET_ROLES", "SET_CUSTOMERS"]),
    ...mapMutations("dealerCompany", ["SET_ACCESS_GROUPS", "SET_MANAGERS"]),
    ...mapMutations("dealerUsers", ["SET_COMPANIES"]),
    ...mapMutations("accessGroups", ["SET_STORAGES"]),

    async reloadCompanies() {
      this.loading = true;
      const allCompanies = await getCompanies("without");

      this.SET_COMPANIES([...allCompanies.data, ...this.companies]);
      this.loading = false;
    },

    async getData() {
      const { customerApplication } = await this.$get(customerApplicationQuery, {
        id: this.$route.params.id,
      });

      const allCompanies = await getCompanies("without");

      if (customerApplication) {
        const {
          companyName,
          companyType,
          companyCode,
          taxNumber,
          comment,
          user,
          region,
          manager,
          processingManager,
          sentToOneC,
        } = customerApplication;

        const { firstName, lastName, phone, email, roles, id, detail } = user;

        const { name, accessGroup, storage, companies, id: customerId } = detail.customer || {};

        this.customerId = customerId;

        if (companies) {
          this.companies =
            companies.map((company) => {
              company.id = parseInt(company.id);

              return company;
            }) || [];
        }

        this.form = {
          companyName,
          companyType,
          companyCode,
          name: name ? name : companyName,
          taxNumber,
          comment,
          firstName,
          lastName,
          phone,
          email,
          sentToOneC,
          managerId: manager?.id,
          processingManagerId: processingManager?.id,
          regionId: region?.id,
          userId: id,
          roleId: roles[0]?.id,
          accessGroupId: accessGroup?.id,
          storageId: storage?.id,
          companies: companies ? companies.map((company) => parseInt(company.id)) : [],
        };

        this.SET_COMPANIES([...allCompanies.data, ...this.companies]);
      }
    },

    async getAccessGroups() {
      const { accessGroups } = await this.$get(accessGroupsQuery);

      if (!this.form.accessGroupId) {
        this.form.accessGroupId = accessGroups[0].id;
      }

      this.SET_ACCESS_GROUPS(accessGroups);
    },

    async getStorages() {
      const { storages } = await this.$get(storagesQuery);

      this.SET_STORAGES(storages);
    },

    async getRoles() {
      const { roles } = await this.$get(rolesQuery);

      this.SET_ROLES(roles);
    },

    async getManagers() {
      const { oneCEmployees } = await this.$get(managersQuery);

      if (oneCEmployees) {
        this.SET_MANAGERS(oneCEmployees);
      }
    },

    async updateMutation(status) {
      const { updateCustomerApplication } = await this.$post(
        updateCustomerApplicationMutation,
        {
          id: this.$route.params.id,
          status: status,
          comment: this.form.comment,
          companyName: this.form.companyName,
          regionId: this.form.regionId,
          managerId: this.form.managerId,
          processingManagerId: this.form.processingManagerId,
          customer: {
            name: this.form.name,
            companies: this.form.companies,
            storageId: this.form.storageId,
            accessGroupId: this.form.accessGroupId,
          },
          dealer: {
            roleId: this.form.roleId,
          },
        },
        {
          delaySuccessNotify: true,
        }
      );

      if (updateCustomerApplication.id) this.goToList();
    },

    async onApproveButton(status) {
      this.$refs.userForm.emitForm();

      if (this.form.isValid) {
        await this.updateMutation(status);
      }
    },

    async onSaveButton(status) {
      this.$refs.userForm.emitSaveForm();

      await this.updateMutation(status);
    },

    async onRejected(status) {
      await this.updateMutation(status);
    },

    async sendToOneC(form) {
      await this.$post(sendCustomerApplicationToOneCMutation, {
        id: this.$route.params.id,
        ...form,
      });
      await this.getData();
    },

    goToList() {
      this.$router.push({ name: "NewApplications" });
    },
  },
};
</script>

<template>
  <OurPage
    class="users-add"
    :title="$t('title.newApplications.details')"
    :back-route="backRoute"
    width="md"
  >
    <ApplicationsForm
      ref="userForm"
      v-model="form"
      class="user-form"
      edit-form
      :loading="loading"
      @reload="reloadCompanies"
      @send="sendToOneC"
    />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.confirm')" @click="onApproveButton('approved')" />
      <!--      <OurButton-->
      <!--        v-if="havePermissionEdit"-->
      <!--        class="button"-->
      <!--        :text="$t('button.save')"-->
      <!--        @click="onSaveButton('pending')"-->
      <!--      />-->

      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>

    <template #footer-right>
      <OurButton
        v-if="havePermissionDelete"
        class="button"
        variant="thirdary"
        :text="$t('button.reject')"
        @click="onRejected('rejected')"
      />
    </template>
  </OurPage>
</template>

<style lang="postcss" scoped>
.users-add {
  @apply relative;

  .user-form {
    @apply mb-6;
  }
}
</style>
