import gql from "graphql-tag";

export const customerApplicationQuery = gql`
  query customerApplication($id: ID!) {
    customerApplication(id: $id) {
      id
      companyName
      companyCode
      companyType
      taxNumber
      comment
      sentToOneC
      manager {
        id
      }
      processingManager {
        id
      }
      region {
        id
      }
      user {
        id
        firstName
        lastName
        phone
        email
        detail {
          ... on Dealer {
            customer {
              id
              name
              storage {
                id
              }
              accessGroup {
                id
              }
              companies {
                id
                name
              }
            }
          }
        }
        roles {
          id
          name
        }
      }
    }
  }
`;
