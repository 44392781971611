import gql from "graphql-tag";

export const updateCustomerApplicationMutation = gql`
  mutation updateCustomerApplication(
    $id: ID!
    $companyName: String
    $customer: CustomerInput
    $dealer: DealerInput
    $comment: String
    $status: CustomerApplicationStatus
    $regionId: ID
    $managerId: ID
    $processingManagerId: ID
  ) {
    updateCustomerApplication(
      id: $id
      comment: $comment
      status: $status
      companyName: $companyName
      customer: $customer
      dealer: $dealer
      regionId: $regionId
      managerId: $managerId
      processingManagerId: $processingManagerId
    ) {
      id
    }
  }
`;

export const sendCustomerApplicationToOneCMutation = gql`
  mutation sendCustomerApplicationToOneC(
    $id: ID!
    $companyName: String!
    $regionId: ID!
    $managerId: ID!
    $processingManagerId: ID!
  ) {
    sendCustomerApplicationToOneC(
      id: $id
      companyName: $companyName
      regionId: $regionId
      managerId: $managerId
      processingManagerId: $processingManagerId
    ) {
      id
    }
  }
`;
